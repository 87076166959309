import React, { useRef, useState } from 'react';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import Features from '../home/features/features.component.jsx';
import Navbar from '../header/header.component.jsx';
import Started from '../home/how-to-get-started/howtogetstarted.component.jsx';
import Footer from '../footer/footer.component.jsx';
// import item1 from '../../assets/pet_banners/Banner-1.jpg';
// import item2 from '../../assets/pet_banners/Banner-2.jpg';
// import item3 from '../../assets/pet_banners/Banner-3.jpg';
// import item4 from '../../assets/pet_banners/Banner-4.jpg';
// import Play from '../../assets/play.png';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
// import Video from '../../assets/key-how-it-works-video.mp4'
import '../home/home.component.css';

function Home() {
    const carouselRef = useRef(null);
    const totalPages = 4;
    let resetTimeout;
    const [playvideo, setplayvideo] = useState(false);
    const handleNotFound = () => setplayvideo(false);
    const playvideopopup = () =>{
        setplayvideo(true)
    }
    return (
        <div className="home">
            <Navbar/>
            <div className="home__landing">
                <div className="home__para new_para_home">
                    <h2 style={{marginTop:"4rem"}}>Keep Your Wallet Secure!</h2>
                    <p>Are you looking for a way to keep your wallet secured and find it if you lose it? Get tag8’s Genuine Leather Men's RFID Wallet. Simple, Clean design and Classic style are the hallmarks of men's wallets and tag8 walks the extra mile to achieve that perfection. We strive for fine quality and uniqueness. tag8 wallet comes with a QR enabled membership card made up of PVC. You get notified when the finder tries to reach you by scanning the QR code on the membership card. Finally reuniting with a lost & misplaced wallet is made easier. This wallet is skillfully crafted from high-quality genuine leather, built with RFID-blocking mechanisms which encase your cards in a material that protects your valuable information from unauthorized scans. Compact yet Spacious: 1 Currency Compartments, 1 Coin Pocket, 4 Card Slots 2 Additional card Compartments. Also, it is the perfect gifting solution for men on special occasions such as Father’s Day, a Birthday, an Anniversary or Diwali.</p>
                    <p>Legal Disclaimer:
tag8 Wallet Finder case needs to be registered for service to be activated. Simply call or message on WhatsApp +91 9029008248 to activate.</p>
                    <div style={{ display: 'flex', marginLeft: "40px" }} className="col-md-12 col-sm-12 col-12 play-btn">
                        <div _ngcontent-xsi-c153="" className="boxplay" style={{position:'relative'}}>
                            <Button onClick={playvideopopup} className="playbutton" >
                                <img src={"https://storage.googleapis.com/pettag/qr/play.png"} height="50px" className="playbuttonimage" weight="50px" style={{ 'border-radius': '50%', 'z-index': '1' }} />
                            </Button>
                            <span className="span1"></span>
                            <span className="span2"></span>
                            <span className="span3"></span>
                        </div>
                    </div>
                </div>
                <Carousel className="home__carousel" ref={carouselRef} onNextEnd={({ index }) => {
                    clearTimeout(resetTimeout)
                    if (index + 1 === totalPages) {
                        resetTimeout = setTimeout(() => {
                            carouselRef.current.goTo(0)
                        }, 5000) // same time
                    }
                }} enableMouseSwipe={false} infinite showArrows={false} itemsToShow={1} enableAutoPlay autoPlaySpeed={5000}>
                    <img
            className="carousel__image"
            src={
              "https://storage.googleapis.com/pettag/qr/pet_banners/walletBanner-1.jpg"
            }
          />
          <img
            className="carousel__image"
            src={
              "https://storage.googleapis.com/pettag/qr/pet_banners/walletBanner-2.jpg"
            }
          />
          <img
            className="carousel__image"
            src={
              "https://storage.googleapis.com/pettag/qr/pet_banners/walletBanner-3.jpg"
            }
          />
          <img
            className="carousel__image"
            src={
              "https://storage.googleapis.com/pettag/qr/pet_banners/walletBanner-4.jpg"
            }
          />

                </Carousel>
            </div>
            <Features />
            <Started />
            <Footer />
            <Dialog disableBackdropClick open={playvideo} onClose={handleNotFound} aria-labelledby="form-dialog-title">
                <DialogContent >
                    <video className="videoplayer" height="100%" width="100%" controls autoplay="true">
                        <source src={"https://storage.googleapis.com/pettag/qr/assets/key-how-it-works-video.mp4"} type="video/mp4" />
                    </video>
                    <div className="row text-center">
                        <div className="col">
                            <button className="mt-4" style={{'width':'200px', 'letter-spacing': '1px'}} onClick={handleNotFound}>CLOSE</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default Home
